import PropTypes from "prop-types";
import clsx from "clsx";
import Swiper, { SwiperSlide } from "../../components/swiper";
import BrandLogoOneSingle from "../../components/brand-logo/BrandLogoOneSingle";
import React, { useEffect } from "react";
import useTranslationHook from "../../hooks/useTranslationHook";
import { HomepageBrand, IGOOGLERATING } from "../../shared/commonUtils";
import useHomePageReviews from "../../hooks/useGetHomePage/useHomePageReviews";
interface IBussinessReviewOne {
  spaceBottomClass: any;
  spaceTopClass: any;
}
const BussinessReviewOne: React.FC<IBussinessReviewOne> = (
  props: IBussinessReviewOne
) => {
  // const {loading,getHomePageBrands,homePageBrandLogo} = useGetHomePage();
  const { t } = useTranslationHook();
  const { getBussinessRating, ratingsData } = useHomePageReviews();
  const { spaceBottomClass, spaceTopClass, homePageBrandLogo } = props;
  const getAverageRating = (ratings: IGOOGLERATING[]) => {
    const numberOfRatings = ratings.length;
    var sumOfTotalRating = 0;
    var finalRating = 0;
    if (ratings.length > 0) {
      ratings.forEach((item) => {
        sumOfTotalRating = sumOfTotalRating + item.rating;
      });
    }
    finalRating = sumOfTotalRating / numberOfRatings;
    return finalRating;
  };
  useEffect(() => {
    getBussinessRating();
  }, []);

  return (
    <div className={clsx("brand-logo-area", spaceBottomClass, spaceTopClass)}>
      <div className="container">
        <div className=" comman-heading text-center">{t("txt_review_top")}</div>

        <>
          <div className="bussiness-review d-flex justify-content-center">
            <div className="text-center">
              <div className="review-category-heading">Google</div>
              <div class=" ratting-star">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <span class="rating-count">
                {t("txt_ratings")}: {getAverageRating(ratingsData)}
              </span>
              {/* Based on */}
              <span class="base-on">
                {t("txt_review_bases")} {ratingsData.length} {t("txt_reviews")}
              </span>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

BussinessReviewOne.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default BussinessReviewOne;
