import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Logo from "../../components/header/Logo";
import IconGroup from "../../components/header/IconGroup";
import NavMenu from "../../components/header/NavMenu";
import MobileMenu from "../../components/header/MobileMenu";
import LanguageCurrencyChanger from "../../components/header/sub-components/LanguageCurrencyChanger";
import { ICATEGORY } from "../../shared/commonUtils";
// import AnnouncementBar from "../announcement-bar/AnnouncementBar";
interface IHeaderTwoProps {
  homePageCategories: ICATEGORY[];
}
const HeaderTwo: React.FC<IHeaderTwoProps> = (props: IHeaderTwoProps) => {
  const { bussinessData } = useSelector((state: any) => state.bussinessData);
  const { homePageCategories } = useSelector(
    (state: any) => state.homePageCategories
  );
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const currency = useSelector((state: any) => state.currency);

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header?.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // console.log(window.scrollY);
    if (window.scrollY > 150) {
      setScroll(window.scrollY);
    } else {
      setScroll(0);
    }
  };

  return (
    <header className="header-area bg-glaucous clearfix header-hm9 transparent-bar">
      {/* <AnnouncementBar extraClass={`${scroll > headerTop && "d-none"}`} /> */}

      <div className="container">
        <div className="header-top-area d-none d-lg-block">
          <div className="row  align-items-center">
            <div className="col-lg-5 col-md-8 col-12">
              {/* language currency changer */}
              <LanguageCurrencyChanger currency={currency} />
            </div>
            <div className="col-lg-2 d-none d-lg-block text-center">
              {/* header logo */}
              <Logo
                imageUrl={bussinessData?.site_settings?.siteLogo}
                // imageUrl={"https://dev.taiftec.com/sites/watniya/img/logo.png"}
                // imageUrl="/assets/img/logo/logo.png"
                logoClass="logo-hm-9"
              />
            </div>
            <div className="col-lg-5 col-md-4 col-12">
              {/* Icon group */}
              <IconGroup />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header-bottom sticky-bar header-res-padding header-padding-2 ${
          scroll > headerTop ? "stick" : ""
        }`}
      >
        {/* <AnnouncementBar extraClass={`${scroll < headerTop && "d-none"}`} /> */}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 d-block d-lg-none">
              {/* header logo */}
              <Logo imageUrl={bussinessData?.site_settings?.siteLogo} />
            </div>
            <div className="col-6 d-block d-lg-none">
              {/* Icon group */}
              <IconGroup />
            </div>

            <div className="col-xl-12 col-lg-12 d-none d-lg-block">
              {/* Nav menu */}
              <NavMenu homePageCategories={homePageCategories} />
            </div>
          </div>
          {/* mobile menu */}
          <MobileMenu />
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
